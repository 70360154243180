<template>
    <el-container>
        <el-header class="el-header">
            <TopHeader/>
        </el-header>
        <el-container class="under">
            <el-aside class="el-aside" width="200px">
                <AsideNavigation/>
            </el-aside>
            <el-main class="el-main">
                <div v-if="$route.path === '/'">
                    <p>Welcome use DPS.</p>
                    <p>If there are problem in use, or there are suggestions for improvement, please contact <strong>Kailian.Huang@easyview.com.hk</strong>.</p>
                    <p>Thank you for using it.</p>
                </div>
                <router-view v-else></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import AsideNavigation from "@/components/AsideNavigation";

export default {
    name: "MainPanel",
    components: {TopHeader, AsideNavigation},
    // mounted() {
    //     const clientVersion = localStorage.getItem(process.env.VUE_APP_LS_KEY_VERSION);
    //     const serverVersion = process.env.VUE_APP_VERSION;
    //     if (clientVersion !== serverVersion) {
    //         localStorage.setItem(process.env.VUE_APP_LS_KEY_VERSION, serverVersion);
    //         this.$router.go(0);
    //     }
    // }
}
</script>

<style scoped>
.el-header {
    background-color: #e1eeff;
    color: #333;
    /*text-align: right;*/
    font-size: 12px;
    line-height: 60px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    padding-bottom: 60px;
    z-index: 1000; /* Adjust as needed to ensure it's above other elements */
}

.el-aside {
    text-align: left;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.under {
    margin-top: 60px;
}

.el-main {
    height: 800px;
  overflow: hidden;
}

</style>
