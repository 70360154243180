<template>
  <div>
    <div style="display: flex; justify-content: center;">
      <!-- title 单独一行，居中显示 -->
      <div style="font-weight: bold; font-size: 20px; display: flex; align-items: center; flex-wrap: nowrap; width: 35%;">
        <p style="width: 30%">{{ title }}</p>
        <el-input v-model="testTitle" placeholder="请输入测试标题：第一单元测试eg."></el-input>
      </div>
    </div>

    <div style="margin-top: 20px; margin-bottom: 20px;">
      <el-row v-for="(word, index) in words" :key="word.id" class="row-item" style="display: flex; justify-content: center;">
        <el-col :span="5">
          <el-input
              class="col-item"
              ref="ac"
              v-model="word.en"
              placeholder="请输入英文"
          />
        </el-col>
        <el-col :span="3">
          <el-input
              class="col-item"
              ref="dp"
              v-model="word.cn"
              align="left"
              type="text"
              placeholder="请输入中文"
          />
        </el-col>
        <el-col :span="2">
          <el-button type="danger" plain icon="el-icon-delete" @click="removeRow(index)">删除</el-button>
        </el-col>
      </el-row>
    </div>

    <el-button type="primary" @click="addRow" >添加</el-button>
    <el-button type="success" @click="submit" :disabled="words.length === 0">提交</el-button>

    <div>
      已输入单词数: {{ words.length }}
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { MessageUtil, MessageLevel } from '@/utils/MessageUtil';
import { ElMessageBox, ElMessage } from 'element-plus';
import axios from 'axios';
import config from '../../config'; // 根据config.js文件的实际位置调整路径

export default {
  name: 'EnterWords',
  props: ['title'],
  setup() {
    // Define reactive variables

    const runBanks = ref([]);
    const words = ref([]);
    const testTitle = ref(null);

    // Run job method from father-component
    const prepareDataToSend = () => {

      console.log('prepareDataToSend')
      const contentData = words.value.map(word => ({
        en: word.en,
        zh: word.cn,
      }));

      const dataToSend = {
        // t_id: localStorage.getItem('t_id'),
        userKey: localStorage.getItem('__ACC_U__'),
        title: testTitle.value,
        content: JSON.stringify(contentData),
      };

      //console.log('t_id' + localStorage.getItem('t_id'));
      console.log(dataToSend);

      return dataToSend;
    };

    const submit = async () => {
      let ok = true;
      console.log(words.value.length);
      let emptyInputFound = false; // Add this flag to avoid showing multiple error messages
      // check the title input box first
      if (!testTitle.value || typeof testTitle.value === 'undefined') {
        MessageUtil.show('请完成所有输入项', MessageLevel.WARNING, 3);
        return;
      }
      for (let i = 0; i < words.value.length; i++) {
        let word = words.value[i];
        console.log(word);
        console.log(word['en']);
        if (!word['en'] || word['en'] === null || typeof word['en'] === 'undefined') {
          emptyInputFound = true; // Set the flag to true
          break;
        }
        console.log(word['cn']);
        if (!word['cn'] || word['cn'] === null || typeof word['cn'] === 'undefined') {
          emptyInputFound = true; // Set the flag to true
          break;
        }
      }
      if (emptyInputFound) { // If the flag is true, display the message box and break the loop
        MessageUtil.show('请完成所有输入项', MessageLevel.WARNING, 3);
        return;
      }

      ElMessageBox.confirm('请确认是否运行？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(async () => {
            // The code inside this block will be executed when the user clicks '确定'
            const dataToSend = prepareDataToSend();

            await axios
                .post(`${config.baseURL}/insertTest`, dataToSend)
                .then((response) => {
                  if (response && response.status === 200) {
                    // 仅在成功时清除行并显示成功消息
                    MessageUtil.show('提交成功', MessageLevel.SUCCESS, 3);
                    clearRows();
                  } else {
                    // 显示其他错误消息，例如网络问题
                    MessageUtil.show('提交失败', MessageLevel.ERROR, 3);
                  }
                })
                .catch((error) => {
                  // 显示异常错误消息
                  MessageUtil.show('提交失败', MessageLevel.ERROR, 3);
                });
          })
          .catch(() => {
            // This block executes if the user clicks '取消'
            MessageUtil.show('已取消运行', MessageLevel.INFO, 3);
          });
    };

    const addRow = () =>  {
      words.value.push({
        en: '',
        cn: null
      });
    };

    const removeRow = (index) => {
      // 使用runBanks.value来操作数组方法
      words.value.splice(index, 1);
    };

    const clearRows  = () =>  {
      words.value.splice(0, words.value.length);
    };

    // Other methods can be added similarly

    return {
      words,
      testTitle,
      submit,
      prepareDataToSend,
      addRow,
      removeRow,
      clearRows
      // Other methods can be added here
    };
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
