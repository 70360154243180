<template>
  <div>
    <el-input class="input" v-model="input" type="file" placeholder="Please input" />
    <el-button class="button" type="primary">文件处理</el-button>
  </div>
</template>

<script>
  import { ElButton, ElInput } from 'element-plus'
  import { ref } from 'vue'
  
  export default {
    components: { ElButton,ElInput },
  }

</script>
<style scoped>
.input {
  display: inline;
  margin: 20px 30px;
}
.button {
  width: 90px;
}
</style>