<template>
  <div class="introduction">
    <h1>录入班级信息</h1>

    <!-- 班级列表渲染 -->
    <div v-for="(classInfo, index) in classInfoList" :key="index" class="input-row">
      <div class="input-container">
        <el-input v-model="classInfoList[index].name" placeholder="输入班级名称" class="narrow-input" @input="isChanged = true"></el-input>
        <el-button type="danger" plain @click="removeRow(index)" class="delete-button">删除</el-button>
      </div>
    </div>

    <!-- 添加和提交按钮 -->
    <el-button type="primary" @click="addRow" :disabled="isSubmitting">添加</el-button>
    <el-button type="success" @click="submitClassInfo" :disabled="!isChanged || isSubmitting">提交</el-button>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import config from '../../config'; // 根据config.js文件的实际位置调整路径

export default {
  name: 'InfoLoad',
  setup() {
    const classInfoList = ref([]);
    const isChanged = ref(false);
    const isSubmitting = ref(false);

    const fetchClassList = async () => {
      const params = {
        teacherId: 123
      };

      try {
        const response = await axios.get(`${config.baseURL}/getClassListByTid`, {
          params: params
        });

        classInfoList.value = response.data.map(classInfo => ({ name: classInfo }));
        isChanged.value = false;
      } catch (error) {
        console.error('Error fetching class list:', error);
      }
    };

    const validateInputs = () => {
      for (let classInfo of classInfoList.value) {
        if (!classInfo.name) {
          ElMessage({
            message: 'Please fill in all input boxes before submitting.',
            type: 'warning',
            duration: 2000
          });
          return false;
        }
      }
      return true;
    };
    const submitClassInfo = async () => {
      if (!validateInputs()) {
        return;
      }

      isSubmitting.value = true;
      const requestData = {
        teacherId: 123,
        classInfoList: classInfoList.value
      };

      try {
        await axios.post(`${config.baseURL}/submitClassList`, requestData);
        ElMessage({
          message: 'Class info submitted successfully!',
          type: 'success',
          duration: 2000
        });
        isChanged.value = false;
      } catch (error) {
        console.error('Error submitting class info:', error);
      } finally {
        isSubmitting.value = false;
      }
    };

    const addRow = () => {
      classInfoList.value.push({ name: '' });
      isChanged.value = true;
    };

    const removeRow = (index) => {
      classInfoList.value.splice(index, 1);
      isChanged.value = true;
    };

    fetchClassList();

    return {
      classInfoList,
      addRow,
      removeRow,
      submitClassInfo,
      isChanged,
      isSubmitting
    };
  }
}
</script>


<style scoped>
.narrow-input {
  width: 25%; /* Adjust this value as needed */
}

.input-container {
  padding-bottom: 10px;
}
</style>
