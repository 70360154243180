<template>
  <div>
    <!-- 输入学生学号 -->
    <el-row>
      <el-col :span="5">
        <!-- 输入学生学号 -->
        <el-input v-model="studentId" placeholder="请输入学生学号"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="queryData">查询</el-button>
      </el-col>
    </el-row>

    <!-- 表格 -->
    <el-table :data="tableData" v-if="tableData.length">
      <el-table-column label="学生姓名" prop="stuName"></el-table-column>
      <el-table-column label="学号" prop="stuNo"></el-table-column>
      <el-table-column label="考试标题" prop="title"></el-table-column>
      <el-table-column label="得分" prop="score"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import config from '../../config'; // 根据config.js文件的实际位置调整路径

export default {
  name: 'StudentScorePage',
  setup() {
    // 响应式数据
    const studentId = ref(''); // 学生学号
    const tableData = ref([]); // 表格数据
    const showTable = ref(false); // 是否显示表格

    // 模拟查询操作，这里使用 setTimeout 模拟异步请求数据
    // 查询数据
    const queryData = () => {
      if (studentId !== '' ) {
        axios.get(`${config.baseURL}/getScoreByUserKeyAndStuId`, {
          params: { userKey: localStorage.getItem('__ACC_U__'),
            stuId: studentId.value,}})
            .then(response => {
              tableData.value = response.data;
              console.log(tableData.value)
            })
            .catch(error => {
              console.error(error);
            });
      }
    };

    return {
      studentId,
      tableData,
      showTable,
      queryData
    };
  }
};
</script>

<!-- 使用 Element Plus 组件 -->
<style>
/* 可以在这里添加样式 */
</style>
