<template>
  <div class="header-container">
    <router-link to="/" class="logo-link">
      <el-image :src="logo" fit="contain" class="logo"/>
    </router-link>
    <el-dropdown class="user-dropdown">
            <span class="el-dropdown-link">
                <el-icon><UserFilled /></el-icon>
                {{ userEmail }}
            </span>
      <template v-slot:dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">Sign Out</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>


<script>
import logoUrl from "@/assets/logo.png";
import { ref } from "vue";
import {useRouter} from "vue-router";
// 具体引入你需要的 Element Plus 组件
import { ElDropdown, ElDropdownMenu, ElDropdownItem, ElImage } from "element-plus";

export default {
  name: "TopHeader",

  setup() {
    const router = useRouter(); // 使用 useRouter 获取路由对象
    const logo = logoUrl;
    const userEmail = ref("yuan"); // 使用 ref 创建响应式数据

    const handleDropdownVisibleChange = (visible) => {
      // 在这里处理下拉框显示状态的变化
      // 你可以在这里添加显示和隐藏的逻辑
    };

    const logout = () => {
      // 在这里实现登出的逻辑
      localStorage.removeItem('__ACC_U__')
      localStorage.removeItem('__ACC_P__');
      router.push('/login');
    };

    const init = () => {
      // 在这里实现登出的逻辑
      console.log('init')
      userEmail.value = localStorage.getItem('__ACC_U__')
    };

    init()



    return {
      logo,
      userEmail,
      logout,
      handleDropdownVisibleChange,
      init
    };
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px; /* Adjust padding as needed */
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
}

.user-dropdown {
  display: flex;
  align-items: center;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
}

</style>

