<template>
  <div>
    <el-select v-model="selectedClass" placeholder="请选择班级">
      <el-option v-for="cls in classOptions" :key="cls" :label="cls" :value="cls"></el-option>

    </el-select>
    <el-select v-model="selectedExam" placeholder="请选择考试">
      <el-option v-for="exam in examOptions" :key="exam.title" :label="exam.title" :value="exam.title"></el-option>
    </el-select>
    <el-button @click="queryData" :disabled="!selectedClass || !selectedExam">查询</el-button>

    <el-table :data="tableData" v-if="tableData.length">
      <el-table-column prop="stuName" label="学生姓名"></el-table-column>
      <el-table-column prop="stuNo" label="学号"></el-table-column>
      <el-table-column prop="score" label="考试成绩"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { ElSelect, ElOption, ElButton, ElTable, ElTableColumn } from 'element-plus';
import config from '../../config'; // 根据config.js文件的实际位置调整路径

export default {
  components: {
    ElSelect,
    ElOption,
    ElButton,
    ElTable,
    ElTableColumn,
  },
  setup() {
    const selectedClass = ref('');
    const selectedExam = ref('');
    const classOptions = ref([]);
    const examOptions = ref([]);
    const tableData = ref([]);

    // 请求班级数据和考试数据
    const fetchData = () => {
      // 使用 axios 请求数据
      axios.get(`${config.baseURL}/getClassListByTid`, { params: { teacherId: '123' }})
          .then(response => {
            classOptions.value = response.data;
            console.log(classOptions.value)
          })
          .catch(error => {
            console.error(error);
          });

      axios.get(`${config.baseURL}/getTestByUserKey`, { params: { userKey: '123' }})
          .then(response => {
            examOptions.value = response.data;
          })
          .catch(error => {
            console.error(error);
          });
    };

    // 查询数据
    const queryData = () => {
      if (selectedClass.value && selectedExam.value) {
        axios.get(`${config.baseURL}/getClassScore`, {
            params: { userKey: localStorage.getItem('__ACC_U__'),
              className: selectedClass.value,
              testTitle: selectedExam.value }})
            .then(response => {
              tableData.value = response.data;
            })
            .catch(error => {
              console.error(error);
            });
      }
    };

    // 页面加载时请求数据
    fetchData();

    return {
      selectedClass,
      selectedExam,
      classOptions,
      examOptions,
      tableData,
      queryData,
    };
  },
};
</script>

<style>
/* 可以在这里添加样式 */
</style>
