<template>
  <div class="registration-form">
    <el-form :model="form" label-width="120px">
      <!-- 用户名 -->
      <el-form-item class="form-item">
        <label for="username" class="form-label">用户名：</label>
        <el-input id="username" v-model="form.username" class="form-input"></el-input>
      </el-form-item>

      <!-- 密码 -->
      <el-form-item class="form-item">
        <label for="password" class="form-label">密&nbsp&nbsp码：</label>
        <el-input id="password" v-model="form.password" type="password" class="form-input"></el-input>
      </el-form-item>

      <!-- 邮箱 -->
      <el-form-item class="form-item">
        <label class="form-label">邮  箱：</label>
        <div class="flex-container">
          <el-input v-model="form.email" class="form-input" ></el-input>
          <el-button @click="validateEmail" class="captcha-button">验证邮箱</el-button>
        </div>
      </el-form-item>

      <!-- 验证码 -->
      <el-form-item class="form-item">
        <label class="form-label">验证码：</label>
        <div class="flex-container">
          <el-input v-model="form.captcha" class="form-input"></el-input>
        </div>
      </el-form-item>

      <!-- 注册按钮 -->
      <el-form-item class="form-item">
        <el-button type="primary" @click="submitForm" class="submit-button">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>



<script>
import axios from 'axios';
import { ref } from 'vue';
import { ElForm, ElFormItem, ElInput, ElButton } from 'element-plus';
import config from '../../config'; // 根据config.js文件的实际位置调整路径

export default {
  name: 'Register',
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  setup() {
    const form = ref({
      username: '',
      password: '',
      email: '',
      captcha: '',
      captcha_email: ''
    });

    const message = ref(''); // 定义响应式数据

    let backendCaptcha = ''; // 存储后端返回的验证码

    const validateEmail = async () => {
      console.log('sendCaptcha');
      // 在这里实现发送验证码的逻辑
      // 1. 获取邮箱
      const email = form.value.email;
      console.log(form.value.email);
      // 2. 验证邮箱是否合法
      if (!email) {
        message.value = '邮箱不能为空';
        return;
      }
      // 3. 生成一个用于比对的六位随机数字
      backendCaptcha = Math.floor(Math.random() * 1000000);
      console.log(backendCaptcha);
      //console.log(email);

      // 4. 将captcha_email作为参数发送请求到后端
      const params = {
            email: form.value.email,
            code: backendCaptcha,
            type: 'activate'
        };
      try {
          const response = await axios.post(`${config.baseURL}/sendEmail`, params);
          console.log(response.data);

          switch (response.data) {
            case 'success':
              message.value = '验证码已发送';
              break;
            case 'email_exist':
              message.value = '邮箱已存在';
              break;
            default:
              message.value = '发送验证码时发生错误';
              break;
          }
       } catch (error) {
        console.error(error);
        message.value = '发送请求时发生错误';

      }

      alert(message.value)
    };

    const submitForm = () => {
      // 在这里实现提交表单的逻辑
      // 校验用户输入的验证码和后端返回的验证码是否一致
      const userCaptcha = form.value.captcha; // 用户输入的验证码
      if (userCaptcha != backendCaptcha) {
        // 提示用户验证码错误...
        message.value = '验证码错误';
        alert(message.value);
        return;
      }

      // 1. 获取用户输入的用户名、密码
      const username = form.value.username;
      const password = form.value.password;
      const email = form.value.email;


      // 2. 校验用户名、密码
      if (!username || !password ) {
        message.value = '请填写完整的注册信息';
        alert(message.value);
        return;
      }

      // 3. 发送请求到后端
      const params = {
        username,
        password,
        email
      };
      axios
        .post(`${config.baseURL}/register`, params)
        .then((response) => {
          console.log(response.data);
          message.value = '注册成功';
        })
    };

    return {
      form,
      validateEmail,
      submitForm
    };
  }
};
</script>

<style scoped>
/* 样式 */
.el-form {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.form-item {
  margin-bottom: 20px; /* 调整间距 */
}

.form-label {
  float: left; /* 使用浮动来对齐标签 */
  margin-right: 10px; /* 调整标签的右边距 */
  height: 32px; /* 设置高度与输入框相同 */
  line-height: 32px; /* 设置行高垂直居中文本 */
}

.form-input {
  display: block;
  width: calc(100% - 130px); /* 减去标签宽度和间隔 */
  float: left; /* 使用浮动来对齐输入框 */
}

.flex-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 10px; /* 设置组件之间的间距 */
}

.el-button.captcha-button {
  margin-left: 10px; /* 验证邮箱按钮的左边距 */
}

.submit-button {
  width: 100%; /* 注册按钮宽度 */
}

/* 响应式调整 */
@media (max-width: 768px) {
  .inline-form-item {
    flex-direction: column; /* 在小屏幕上堆叠布局 */
    align-items: flex-start;
  }

  .form-input {
    margin-top: 10px; /* 在小屏幕上输入框顶部间距 */
  }

  .el-button.captcha-button {
    margin-left: 0; /* 在小屏幕上去除按钮的左边距 */
    width: 100%;
  }

  .submit-button {
    margin-top: 20px; /* 在小屏幕上注册按钮顶部间距 */
  }
}
</style>
