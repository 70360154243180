<template>
  <div class="login-page">
    <div class="login-card">
      <div class="avatar">
        <!-- Replace 'avatar.png' with your actual avatar image path -->
        <img src="../assets/avatar.png" alt="User Avatar">
      </div>
      <h2>User Login</h2>
      <el-form :model="loginForm" class="login-form">
        <el-form-item>
          <el-input prefix-icon="el-icon-user" v-model="loginForm.email" placeholder="Email Id"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input prefix-icon="el-icon-lock" type="password" v-model="loginForm.password" placeholder="Password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="handleLogin" class="login-btn">Login</el-button>
        </el-form-item>
      </el-form>
      <div class="link-container">
        <router-link to="/forgot" class="link">找回密码</router-link>
        <router-link to="/register" class="link">注册账号</router-link>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue';
import axios from "axios";
import {MessageLevel, MessageUtil} from "@/utils/MessageUtil";
import {useRouter} from "vue-router";
import config from '../../config'; // 根据config.js文件的实际位置调整路径

export default {

  setup() {

    const router = useRouter(); // 使用 useRouter 获取路由对象
    const loginForm = ref({
      email: '',
      password: ''
    });


    const message = ref(''); // 定义响应式数据



    const handleLogin = async () => {
      const email = loginForm.value.email;
      const password = loginForm.value.password;
      const params = {
        email,
        password
      };


      try {
        const response = await axios.post(`${config.baseURL}/login`, params);
        console.log(response.data);

        switch (response.data) {
          case 'user_not_exist':
            message.value = '邮箱不存在';
            break;
          case 'password_error':
            message.value = '密码错误';
            break;
          case 'login_success':
            message.value = '登录成功';
            break;
          default:
            message.value = '登录失败';
            break;
        }
      } catch (error) {
        console.error(error);
        message.value = '发送请求时发生错误';
      }

      MessageUtil.show(message.value, MessageLevel.WARNING, 3);

      if (message.value === '登录成功') {
        // 路由跳转到home页
        localStorage.setItem('__ACC_U__', email)
        localStorage.setItem('__ACC_P__', password);
        router.push('/home');
      }
    };

    return {
      loginForm,
      handleLogin
    };
  }
};
</script>


<style scoped>
/*.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #8e2de2 0%, #4a00e0 25%, #ff0000 50%, #00ff00 75%);
}*/

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/login-back2.jpg');
  background-size: cover;
  background-position: center;
}

.login-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.avatar {
  margin-bottom: 1rem;
}

.avatar img {
  border-radius: 50%;
  /* Adjust size as needed, assuming a square image */
  width: 100px;
  height: 100px;
}

h2 {
  margin-bottom: 1.5rem;
}

.login-form /deep/ .el-input {
  margin-bottom: 1rem;
}

.login-btn {
  width: 100%;
}

/*a {
  display: block;
  margin-top: 1rem;
  color: #555;
  text-decoration: none;
}*/

.link-container {
  display: flex;
  justify-content: space-around;
  align-items: center; /* 添加这一行 */
}

/*.link-container a {
  color: #555;
  text-decoration: none;
}*/

.link {
  font-size: 14px; /* 调整字体大小 */
  color: #007BFF; /* 设置字体颜色为蓝色 */
  cursor: pointer; /* 设置鼠标悬停时的样式为手型，表示可以点击 */
}
</style>

