<template>
    <el-menu :default-openeds="['1']" router active-text-color="#409eff" text-color="#000000">
        <el-sub-menu index="1">
            <template #title>
                <i class="el-icon-cpu"></i>
                <span class="menu-head">CORE</span>
            </template>
            <el-menu-item index="/core/intro">使用说明</el-menu-item>
            <el-menu-item index="/core/load">信息完善</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="2">
            <template #title>
                <i class="el-icon-document"></i>
                <span class="menu-head">单词</span>
            </template>
            <el-menu-item index="/word/load">单词录入</el-menu-item>
            <el-menu-item index="/word/check">历史查看</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3">
            <template #title>
                <i class="el-icon-document"></i>
                <span class="menu-head">成绩查看</span>
            </template>
            <el-menu-item index="/score/class">班级查看</el-menu-item>
            <el-menu-item index="/score/stu">个人查看</el-menu-item>
        </el-sub-menu>
        
    </el-menu>
</template>

<script>
import { ElMenu, ElSubMenu, ElMenuItem } from 'element-plus'
export default {
    name: "AsideNavigation",
    components: {
        ElMenu,
        ElSubMenu,
        ElMenuItem
    },

}
</script>

<style scoped>
.el-menu-item:focus .el-menu-item:hover {
    background-color: #ecf5ff;
}
.menu-head {
    color: dodgerblue;
    font-weight: bold;
}
/*.el-menu-item.is-active {*/
/*    background-color: #B3C0D1 !important;*/
/*}*/
</style>