<template>
  <div class="forgot-password-page">
    <h1>Forgot Password</h1>
    <el-form :model="resetForm" class="reset-form">
      <el-form-item class="form-email" label="邮箱">
        <div class="email-container">
          <el-input id="email" v-model="resetForm.email" placeholder="请输入您的邮箱"></el-input>
          <el-button type="primary" @click="sendVerificationCode">发送校验码</el-button>
        </div>
      </el-form-item>
      <el-form-item label="校验码">
        <el-input v-model="resetForm.code" placeholder="请输入校验码"></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" v-model="resetForm.newPassword" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码">
        <el-input type="password" v-model="resetForm.confirmNewPassword" placeholder="请再次输入新密码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" class="submit-btn" @click="submitNewPassword">确认提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ElForm, ElFormItem, ElInput, ElButton } from 'element-plus';
import axios from "axios";
import config from "../../config";

export default {
  components: {
    ElForm, ElFormItem, ElInput, ElButton
  },
  setup() {
    const resetForm = ref({
      email: '',
      code: '',
      newPassword: '',
      confirmNewPassword: ''
    });


    const message = ref(''); // 定义响应式数据
    let backendCaptcha = ''; // 存储后端返回的验证码

    const sendVerificationCode = async () => {
      // Implement your logic to send the verification code here
      // 3. 生成一个用于比对的六位随机数字
      backendCaptcha = Math.floor(Math.random() * 1000000);
      console.log("Verification code sent to:", resetForm.value.email);
      const params = {
        email: resetForm.value.email,
        code: backendCaptcha,
        type: 'retrieve'
      };
      try {
        const response = await axios.post(`${config.baseURL}/sendEmail`, params);
        console.log(response.data);

        switch (response.data) {
          case 'success':
            message.value = '验证码已发送';
            break;
          case 'email_not_exist':
            message.value = '邮箱不存在';
            break;
          default:
            message.value = '发送验证码时发生错误';
            break;
        }
      } catch (error) {
        console.error(error);
        message.value = '发送请求时发生错误';
      }

      alert(message.value)
    };

    const submitNewPassword = async () => {
      if (resetForm.value.newPassword !== resetForm.value.confirmNewPassword) {
        alert("密码不一致!");
        return;
      }
      // Implement your logic to reset the password here
      console.log("New password set:", resetForm.value);

      console.log("Verification code sent to:", resetForm.value.email);
      const params = {
        email: resetForm.value.email,
        password: resetForm.value.newPassword,
      };
      try {
        const response = await axios.post(`${config.baseURL}/resetPassword`, params);
        console.log(response.data);

        switch (response.data) {
          case 'success':
            message.value = '密码重置成功';
            break;
          default:
            message.value = '密码重置错误';
            break;
        }
      } catch (error) {
        console.error(error);
        message.value = '密码重置错误';
      }

      alert(message.value)
      // Redirect to login page
      if (message.value === '密码重置成功'){
        window.location.href = '/login'; // Replace with your login route
      }

    };

    return {
      resetForm,
      sendVerificationCode,
      submitNewPassword
    };
  }
};
</script>

<style scoped>
.forgot-password-page {
  width: 400px;
  margin: auto;
  padding-top: 50px;
}

.form-email {
  display: flex;
  justify-content: space-between; /* 添加这一行 */
}

.form-group label {
  writing-mode: horizontal-tb; /* 添加这一行 */
  margin-right: 0.5rem; /* Added margin to label */
}

.reset-form .el-form-item {
  margin-bottom: 20px;
}

.email-container {
  display: flex;
  width: 100%; /* 添加这一行 */
  margin: 0; /* 添加这一行 */
  padding: 0; /* 添加这一行 */
  border: none; /* 添加这一行 */
}
.submit-btn {
  width: 100%;
}
</style>
