/* eslint-disable */

import { ElMessage } from 'element-plus';

// Define enum
class MessageLevel {
    static SUCCESS = 'success';
    static INFO = 'info';
    static WARNING = 'warning';
    static ERROR = 'error';
}

const MessageUtil = {
    show(message, level = MessageLevel.SUCCESS, duration = 0) {
        return ElMessage({
            type: level,
            message: message,
            duration: duration * 1000,
            showClose: true,
        });
    },
}

export { MessageUtil, MessageLevel };

