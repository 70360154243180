/**
 * Created by yuan on 2024/1/14 10:15
 */
const config = {
  development: {
    baseURL: 'http://127.0.0.1:9001'
  },
  production: {
    baseURL: 'http://www.kuibu.site:9001'
  }
};

export default config[process.env.NODE_ENV || 'development'];
