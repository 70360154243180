<template>
  <div class="container">
    <div class="signup-wrapper">
      <div class="form-container">
        <h2 class="title">Sign up</h2>
        <form @submit.prevent="submitForm" >
          <!-- Input fields -->
          <input type="text" placeholder="Your Name" v-model="form.name" required>
          <input type="email" placeholder="Your Email" v-model="form.email" required>
          <input type="password" placeholder="Password" v-model="form.password" required>
          <input type="password" placeholder="Repeat your password" v-model="form.repeatPass" required>
          <!-- Terms of service -->
<!--          <div class="terms-of-service">
            <input type="checkbox" id="terms" required>
            <label for="terms">I agree to the Terms of Service</label>
          </div>-->
          <!-- Submit button -->
          <button type="submit" >Register</button>
        </form>
      </div>
      <div class="image-container">
        <!-- Place your image here -->
        <img src="../assets/signup.png" alt="Sign Up Illustration">
      </div>
    </div>
  </div>
</template>

<script>
import {ElButton, ElForm, ElFormItem, ElInput} from "element-plus";
import {MessageLevel, MessageUtil} from "@/utils/MessageUtil";
import {ref} from "vue";
import axios from "axios";
import config from "../../config";
import {useRouter} from "vue-router";

export default {
  name: 'Register',
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  setup() {
    const router = useRouter(); // 使用 useRouter 获取路由对象

    const form = ref({
      username: '',
      email: '',
      password: '',
      repeatPass: '',
    });

    const message = ref(''); // 定义响应式数据

    let backendCaptcha = ''; // 存储后端返回的验证码

    const validateEmail = async () => {
      console.log('sendCaptcha');
      // 在这里实现发送验证码的逻辑
      // 1. 获取邮箱
      const email = form.value.email;
      console.log(form.value.email);
      // 2. 验证邮箱是否合法
      if (!email) {
        message.value = '邮箱不能为空';
        return;
      }
      // 3. 生成一个用于比对的六位随机数字
      backendCaptcha = Math.floor(Math.random() * 1000000);
      console.log(backendCaptcha);
      //console.log(email);

      // 4. 将captcha_email作为参数发送请求到后端
      const params = {
        email: form.value.email,
        code: backendCaptcha,
        type: 'activate'
      };
      try {
        const response = await axios.post(`${config.baseURL}/sendEmail`, params);
        console.log(response.data);

        switch (response.data) {
          case 'success':
            message.value = '验证码已发送';
            break;
          case 'email_exist':
            message.value = '邮箱已存在';
            break;
          default:
            message.value = '发送验证码时发生错误';
            break;
        }
      } catch (error) {
        console.error(error);
        message.value = '发送请求时发生错误';

      }

      alert(message.value)
    };

    const submitForm = async () => {
      // 在这里实现提交表单的逻辑
      // 校验用户输入的验证码和后端返回的验证码是否一致
      console.log('submitForm')
      if (form.value.password !== form.value.repeatPass) {
        // 提示用户验证码错误...
        message.value = '两次密码不一致';
        MessageUtil.show(message.value, MessageLevel.WARNING, 3);
        return;
      }

      // 1. 获取用户输入的用户名、密码
      const username = form.value.username;
      const password = form.value.password;
      const email = form.value.email;

      // 2. 发送请求到后端
      const params = {
        username,
        password,
        email
      };

      let resMsg = ''

      try {
        const response = await axios.post(`${config.baseURL}/register`, params);
        console.log(`response.data ${response.data}`);
        resMsg =  response.data

      } catch (error) {
        console.error(error);
        resMsg = '发送请求时发生错误';
      }

      if (resMsg === 'emailExist') {
        console.log('aaa')
        MessageUtil.show(resMsg, MessageLevel.WARNING, 3);
      } else if (resMsg === 'success') {
        console.log('bb')
        router.push('/login');
      } else {
        console.log('ccc')
        MessageUtil.show(resMsg, MessageLevel.WARNING, 3);
      }
    };

    return {
      form,
      validateEmail,
      submitForm
    };
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #eceaea; /* Slightly darker background */
}

.signup-wrapper {
  display: flex;
  max-width: 1000px;
  min-height: 618px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  align-items: stretch;
  background-color: #fff;;
}

.form-container {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*background: #fff;*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Subtle shadow for depth */
  border-radius: 5px; /* Rounded corners for the form */
}

/* Adjust input field styles */
input[type="text"],
input[type="email"],
input[type="password"] {
  width: calc(100% - 40px); /* Full width minus padding */
  padding: 15px; /* Increase padding for better spacing */
  margin-bottom: 25px; /* Add more space between fields */
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 16px; /* Larger font size for better readability */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Modern font */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border color and shadow */
}

input[type="text"]:in-range,
input[type="text"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #80bdff; /* Light blue border color */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Light blue shadow for focus and hover */
}


.title {
  /* ... */
  font-weight: 500; /* Medium weight for the title */
}


.terms-of-service input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and label */
}


button[type="submit"] {
  width: 100%; /* Full width of the form */
  padding: 15px 30px; /* Larger padding for a wider button */
  background-color: #007bff; /* Solid color for the button */
  color: #ffffff;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

button[type="submit"]:hover {
  background-color: #0056b3; /* Darker blue on hover for button */
}

.image-container {
  flex: 1;
  display: flex; /* Use flex to center the image */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* This will ensure the image maintains its aspect ratio */
}

/* ... rest of the styles ... */

/* Responsive styles */
@media (max-width: 900px) {
  .signup-wrapper {
    flex-direction: column;
  }

  .image-container {
    display: none; /* Hide image on smaller screens */
    max-height: 300px;
  }
}
</style>
