<template>
  <div class="left-align"> <!-- 添加一个包裹容器，并设置样式 -->
    <el-select v-model="selectedItem" @change="handleSelectChange" :clearable="true" style="width: 300px;">
      <el-option v-for="item in testData" :key="item.id" :label="item.title" :value="item"></el-option>
    </el-select>
    <el-table :data="tableData">
      <el-table-column label="中文名" prop="chineseName"></el-table-column>
      <el-table-column label="英文名" prop="englishName"></el-table-column>
    </el-table>
    <img v-if="selectedItem" :src="imgPath" alt="Image Alt Text" width="430" height="430" />
  </div>
</template>

<script>
import { ref ,onMounted} from 'vue';
import axios from 'axios';
import config from '../../config'; // 根据config.js文件的实际位置调整路径

export default {
  name: 'CheckWords',
  props: ['title'],
  setup() {
    const testData = ref([]); // 使用 ref 创建响应式数据
    const selectedItem = ref(null);
    const tableData = ref([]);
    const imgPath = ref(null);

    // selectedItem 是一个用于存储被选择的标题的变量

    const handleSelectChange = (item) => {
      const selectedData = testData.value.find(item => item.title === selectedItem.value);
      console.log(testData)
      console.log(item.userKey)
      console.log("selectedData:" + selectedData)

      if (item) {
        const parsedData = JSON.parse(item.content);
        tableData.value = parsedData.map(entry => ({
          chineseName: entry.zh,
          englishName: entry.en
        }));
        //将
        imgPath.value = `/opt/practice/javaweb/qrimg/${item.testKey}.png`;
        console.log(imgPath.value)
      } else {
        tableData.value = [];
        imgPath.value = null;
      }
    };

    // 在页面渲染后执行的操作
    onMounted(() => {
      // 在 Vue 3 中，mounted 钩子被移除了，可以在 setup 函数中执行数据的获取
      console.log('onMounted');
      axios
          .get(`${config.baseURL}/getTestByUserKey`,{
            params: {
              userKey: localStorage.getItem('__ACC_U__')
            }})
          .then(response => {
            console.log('response.data', response.data);
            testData.value = response.data;
          })
          .catch(reason => {
            //console.log('BasicPanel catch: ', reason);
            //console.log("t_id:" + localStorage.getItem('t_id'))
          });
    });

    // 在 setup 函数中，返回需要在模板中使用的数据和方法
    return {
      testData,
      selectedItem,
      tableData,
      imgPath,
      handleSelectChange
    };
  },


}
</script>

<style scoped>
.left-align {
  text-align: left; /* 将子元素左对齐 */
  margin-left: 0; /* 为确保没有左边距，可以设置 margin-left 为 0 */
  padding-left: 0; /* 可选：如果有左边距，则同时将 padding-left 设置为 0 */
}
</style>
