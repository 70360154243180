// Import Vue and Vue Router
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

//import Register from '../pages/Register.vue'; // Import Register component
import Register from '../pages/RegisterNew.vue'; // Import Register component
import Element from '../components/Element.vue'; // Import Register component
import Login from '../pages/Log1.vue'; // Import Login component
import Home from '../components/MainPanel.vue'; // Import Login component
import Intro from '../pages/Introduction.vue'; // Import Login component InfoLoad
import InfoLoad from '../pages/InfoLoad.vue'; // Import Login component InfoLoad
import TestLoad from '../pages/TestLoad.vue'; // Import Login component InfoLoad
import TestCheck from '../pages/TestCheck.vue'; // Import Login component InfoLoad
import ScoreClass from '../pages/ScoreClass.vue'; // Import Login component InfoLoad
import ScoreStu from '../pages/ScoreStu.vue'; // Import Login component InfoLoad
import Forgot from '../pages/forgot.vue'; // Import Login component InfoLoad

// Define routes
const routes = [

  { path: '/', component: Home }, // Define root path component
  { path: '/element', component: Element }, // Define root path component
  { path: '/register', component: Register }, // Define /Register path component
  { path: '/login', component: Login }, // Define /Register path component
  { path: '/home', component: Home }, // Define /Register path component
  { path: '/forgot', component: Forgot }, // Define /Register path component
  {
    path: '/core',
    component: Home,
    children:[
      { path: 'intro', component: Intro },
      { path: 'load', component: InfoLoad },
    ]
  },
  {
    path: '/word',
    component: Home,
    children:[
      { path: 'load', component: TestLoad },
      { path: 'check', component: TestCheck },
    ]
  },
  {
    path: '/score',
    component: Home,
    children:[
      { path: 'class', component: ScoreClass },
      { path: 'stu', component: ScoreStu },
    ]
  }
];

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes // Use previously defined routes
});

// Create Vue app and use the router
const app = createApp({});
app.use(router);



router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !localStorage.getItem('__ACC_U__') && !localStorage.getItem('__ACC_P__')) {
    if ((to.path === '/forgot' || to.path === '/register')) {
     // 如果是 forgot 或 register 页面，或者已经登录，不拦截跳转
     next();
   } else{
      next('/login');
      console.log('login');
    }

  } else {
    next();
    console.log('logged in');
  }
});



// Export the router instance
export default router;
