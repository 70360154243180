<template>
  <div class="carousel">
    <div
        class="carousel-item"
        v-for="(item, index) in items"
        :key="index"
        :style="{ display: index === currentIndex ? 'block' : 'none' }">
      <img :src="item.image" :alt="`Image ${index + 1}`" class="carousel-image">
<!--      <img src="../assets/signup.png" :alt="`Image ${index + 1}`" class="carousel-image">-->

      <p class="carousel-text">{{ item.text }}</p>
    </div>
    <!-- 控制按钮 -->

   </div>
  <button @click="prev">上一张</button>
  <button @click="next">下一张</button>
</template>

<script setup>
import { ref } from 'vue';

const items = ref([
  // 确保图片路径是正确的
  { image: require('../assets/loadClass.png'), text: '填写班级信息' },
  { image: require('../assets/loadTest.png'), text: '录入测试' },
  { image: require('../assets/checkTest.png'), text: '查看测试，并拷贝二维码' },
  { image: require('../assets/mini.png'), text: '学生通过微信扫码进入，填写信息，进行默写' },
  // 更多图片和文字
]);

const currentIndex = ref(0);

const next = () => {
  currentIndex.value = (currentIndex.value + 1) % items.value.length;
};

const prev = () => {
  currentIndex.value = (currentIndex.value + items.value.length - 1) % items.value.length;
};
</script>

<style scoped>
.carousel {
  max-width: 1000px;
  margin: auto;
  position: relative;
  overflow: hidden;
  height: 600px; /* 设置固定高度 */
}

.carousel-item {
  width: 100%;
  transition: opacity 0.5s;
  height: 100%; /* 设置轮播项高度 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.carousel-item img {
  max-width: 100%; /* 最大宽度 100%，保持图片比例 */
  max-height: 100%; /* 最大高度 100%，保持图片比例 */
  display: block;
}

.carousel-item p {
  text-align: center;
}

/* 按钮样式 */
button {
  position: absolute;
  bottom: 200px; /* 底部定位 */
  /*left: 50%; !* 水平居中 *!
  transform: translateX(-50%); !* 精确居中 *!*/
  background-color: #4f46e5; /* 蓝色背景 */
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px; /* 圆角 */
  transition: background-color 0.3s; /* 过渡效果 */
}

button:hover {
  background-color: #4338ca; /* 悬停效果 */
}

/* 分别为上一张和下一张按钮添加样式 */
button:nth-child(2) {
  left: 50%;
}

button:nth-child(3) {
  left: 55%;
}
</style>

